.footer .module-footer {
    color: $color-white;

    .module-inner {
        .footer__logo {
            padding: 0 20px;
            max-height: 45px;
        }
        .footer-container .footer-content {
            .footer__content .social-networks__item {
                .fa {
                    color: $color-white;
                    padding-right: 10px;
                }
            }
        }
    }
}
