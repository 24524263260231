.product-availability {

  .product-info__stock {

    .unidades-disponibles,
    .unidades-futuras {
      margin-bottom: 5px;
    }

    .no-today {

      &-zero {
        color: $color-red;
      }
    }
  }
}