.fotorama {
  &__thumb-border {
    border: 2px solid $color-secondary;
  }
}
.fotorama__fullscreen {
  .fotorama__nav{
    .fotorama__active {
      .fotorama__thumb-border {
        border: 2px solid $color-secondary;
      }
    }
  }
}
.catalog-product-view {
  .fotorama__nav {
    .fotorama__thumb-border {
      border: 2px solid $color-secondary;
    }
  }
}
