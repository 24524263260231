// ==========================================================================
// = Global Variables
// ==========================================================================

/**
 * CSS layout debugging
 * @link http://pesticide.io/
 * @type boolean
 */

$pesticide-debug: false;

/**
 * Define width for browsers w/out media query support
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$fix-mqs: false !default;

/**
 * Turn on/off IE specific styles
 * @link http://jakearchibald.github.com/sass-ie/
 * @type boolean
 */

$old-ie: false !default;

// Color Scheme
// =============

// Descriptive colors:
$color-black: #000;
$color-dark: #191919;
$color-dark-light: #3a3a3a;
$color-dark-alt: #575757; //ixia
$color-white: #fff;
$color-white-dirty: #f5f5f5;
$color-light: #d3d3d3; //ixia
$color-grey-dark: #97928e;
$color-grey: #eaeaea; //ixia
$color-grey-alt: #adadad; //ixia
$color-grey-bg: #f2f2f2; //ixia
$color-grey-btn: #ebebeb; //ixia
$color-beige: #f5f5ed;
$color-orange: #ff974e;
$color-blue: #006fc4;
$color-blue-light: #0280ef;
$color-cyan: #76b6bf;
$color-brown: #deb283;
//Unimasa
$color-pink-dark: #b20636;
$color-pink-light: #c83353;
$color-wine: #a62e43;
$color-pink-bg: #faf3f8;
$color-unimasa-dark-grey: #3d3d3d;
$color-unimasa-light-grey: #c7c7c7;


//
$color-grey-light: #e4e4e4;
$color-orange-light: #c07600;
$color-red: #fe3f3f;
$color-red-light: #fae5e5;
$color-green-msg: #82a91a;
$color-green: #90e1cf;

// Special theme color changes
$color-background: $color-white;
$color-primary: $color-unimasa-dark-grey;
$color-text: $color-unimasa-dark-grey;
$color-secondary: $color-unimasa-light-grey;
$color-brown: $color-unimasa-light-grey;
$color-tertiary: $color-unimasa-dark-grey;
$color-menu: $color-primary;
$color-text-header-secondary: $color-primary;
$color-border-search: $color-primary;
$color-switcher-language: $color-primary;
$color_background_minicart: $color-brown ;
$color_text_minicart: $color-black;
$background-color-header: $color-white;
$breadcrumbs-color-primary: $color-secondary;
$breadcrumbs-color-hover-secondary: $color-primary;

$menuBackgroundColor: #f8f8f8;
$color-close: #808080;

// Buttons

$button-primary-background: $color-primary;
$button-primary-color: $color-white;
$button-primary-border: $color-primary;

$button-primary-background-hover: $color-brown;
$button-primary-color-hover: $color-white;
$button-primary-border-hover: $color-brown;

$button-secondary-background: $color-white;
$button-secondary-color: $color-primary;
$button-secondary-border: $color-primary;

$button-tertiary-background: transparent;
$button-tertiary-color: $color-black;
$button-tertiary-border: $color-grey-dark;

$button-tertiary-background-hover: $color-brown;
$button-tertiary-color-hover: $color-white;
$button-tertiary-border-hover: $color-brown;

$breadcrumbs-link: $color-white;

$btn-link-color: $color-secondary;
$btn-link-color-hover: $color-primary;

$welcome-text: $color-grey-alt;

// Base Sizes
// ==========

/**
 * Base font size in used in _mixins.scss
 * @type number
 */

$base-font-size: 16;

/**
 * Base line-height in used in _mixins.scss
 * @type number
 */

$base-line-height: 24;


// Font Stacks
// ===========

/**
 * (optional) URL for Google Fonts import
 * @type string
 */

$google-fonts-url: 'https://fonts.googleapis.com/css?family=Lato|Poppins:400,600,700,900';

/**
 * (optional) names of webfonts imports
 * @type array
 */

$font-primary: "Poppins", Helvetica, Arial, sans-serif;
$font-secondary: "Lato", sans-serif;
$font-icons: FontAwesome;
$font-icons-alt: 'Blank-Theme-Icons';

$fonts: $font-icons-alt;

// Font Weights
// ============

$font-weight-thin: 100;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-regular: 600;
$font-weight-semibold: 700;
$font-weight-bold: 900;


// Breakpoints
// There are no common breakpoints; examples based on the official Sass styleguide
// You'll need to define your own breakpoints to suit your design
// ============

// Bootstrap Breakpoints  . Go to Bootstrap docs

/**
 * Define z-indexes for various elements
 * @link http://www.sitepoint.com/using-sass-maps/
 * @type number
 */

// z-index reference
// Bootstrap Z-index. Go to Bootstrap docs
